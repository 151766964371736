import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const Expense = ({ date }) => {
  const { user, Dashboard } = useContext(AuthContext);

  const handleOfficeExpens = (event) => {
    const form = event.target;
    const expanseType = form.type.value;
    const date = form.date.value;
    const bill = parseFloat(form.bill.value);
    const unit = parseFloat(form.unit.value);
    const note = form.note.value;
    const issuedBy = form.issuedBy.value;
    const months = form.months.value;

    const otherExpanses = {
      name: expanseType,
      paid_amount: bill.toFixed(2),
      qunatity: unit.toFixed(2),
      note: note,
      month: months,
      issue_date: date,
      issued_by: issuedBy,
    };

    axios
      .post("otherExpanses", otherExpanses)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };
  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="officeExpanse_form" className="btn bg-purple-100 px-16 ">
        Others Expanses
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="officeExpanse_form" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="officeExpanse_form" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleOfficeExpens} className="grid grid-col-1 gap-3" method="dialog">
          <h3 className="p-2">Others Expanses Form</h3>
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName}`}
              className="input input-bordered w-full  "
            />
            <select name="type" required className="select select-bordered w-full ">
              <option disabled selected>
                Select Expense Type
              </option>
              <option>House Rent</option>
              <option>Electricity Bill</option>
              <option>Water Bill</option>
              <option>Internet Bill</option>
              <option>Maintanence Cost</option>
              <option>Others</option>
            </select>
            <select name="months" required className="select select-bordered w-full ">
              <option>January</option>
              <option>Fabruary</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
            <input name="date" type="text" disabled value={date} className="input input-bordered w-full  " />
            <input name="bill" type="text" step='any' required placeholder="Bill Amount" className="input input-bordered w-full  " />
            <input name="unit" type="text" required step='any' placeholder="Unit(if any)" className="input input-bordered w-full  " />
            <input name="note" type="text" placeholder="Notes" className="input input-bordered w-full  " />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Expense;
