import React, { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../../Context/AuthProvider";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const UserDetails = () => {
  const { Dashboard } = useContext(AuthContext);
  const searchRef = useRef();
  const [search, setSearch] = useState("");
  const [selectData, setSelectData] = useState(null);
  // const [selectUser, setSelectUser] = useState(null);
  const [id, setID] = useState(null);
  let url = `userDetailsData?`;
  search && (url = url.concat(`s=${search}`));
  const { data: userDetails = [], refetch } = useQuery({
    queryKey: ["userData", search],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });
  let selectUser = userDetails;

  // console.log(selectUSerDetails)
  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };

  const handleEditBtn = (id) => {
    refetch();
    setID(id)
    console.log(selectData,id)
  }
  const user = selectUser.filter(user => user._id == id)
  console.log(user)

  const handleEdit = (e) => {
    e.preventDefault();
    const form = e.target;

    const catagories = form.catagories.value;
    const name = form.name.value;
    const address = form.address.value;
    const contact = form.contact.value;
    const nid = form.nid.value;
    const userNote = form.userNote.value;
    const fname = form.fname.value;
    const mname = form.mname.value;
    const assignedSalary = form.assignedSalary.value;

    axios
      .put(`/userDetailsData/${id}`, { name: name, userNote: userNote, catagories: catagories, address: address, contact: contact, nid: nid, fname: fname, mname: mname , assignedSalary: assignedSalary})
      .then((res) => {
        toast.success("updated");
        refetch();
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  }

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>
      <div>
        <h3 className="text-3xl">Partners Details</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th>Name</th>
                <th>Father's Name</th>
                <th>Mother's Name</th>
                <th>Address</th>
                <th>Contact</th>
                <th>NID/Trade Licesne</th>
                <th>Assigned Salary</th>
                <th>Note</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userDetails?.map((uData, i) => (
                <tr>
                  <th>{i + 1}</th>
                  <td>{uData.catagories}</td>
                  <td>{uData.name}</td>
                  <td>{uData.fname}</td>
                  <td>{uData.mname}</td>
                  <td>{uData.address}</td>
                  <td>{uData.contact}</td>
                  <td>{uData.nid}</td>
                  <td>{uData.assignedSalary}</td>
                  <td>{uData.userNote}</td>
                  <td>
                    <label htmlFor="edit" className="btn bg-red-100 px-16" onClick={() => handleEditBtn(uData._id)}>
                      Edit
                    </label>






                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <input type="checkbox" id="edit" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="edit" className="btn my-2">
              X
            </label>
          </div>
          <form onSubmit={handleEdit} className="">

            {
              user.map((u) =>
              (

                <div className="form-control">
                  <select name="catagories" className="select select-bordered w-full ">
                    <option>{u.catagories}</option>
                    <option>Client</option>
                    <option> Wood Powder Agent</option>
                    <option> Wire Agent</option>
                    <option> Acid Agent</option>
                    <option>Employee</option>
                    <option>Investor</option>
                  </select>
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input type="text" name="name" placeholder="Name" defaultValue={u.name} className="input input-bordered" required />
                  <label className="label">
                    <span className="label-text">Father's Name</span>
                  </label>
                  <input type="text" name="fname" placeholder="Father's Name" defaultValue={u.fname} className="input input-bordered" required />
                  <label className="label">
                    <span className="label-text">Mother's Name</span>
                  </label>
                  <input type="text" name="mname" placeholder="Mother's Name" defaultValue={u.mname} className="input input-bordered" required />

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Address</span>
                    </label>
                    <input type="text" name="address" placeholder="Address" defaultValue={u.address} className="input input-bordered" required />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Contact</span>
                    </label>
                    <input type="number" name="contact" placeholder="Contact" defaultValue={u.contact} className="input input-bordered" required />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">NID/Trade License</span>
                    </label>
                    <input type="text" name="nid" defaultValue={u.nid} placeholder="NID/Trade License" className="input input-bordered" required />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Assigned Salary</span>
                    </label>
                    <input type="text" name="assignedSalary" defaultValue={u.assignedSalary} placeholder="Amount" className="input input-bordered" required />
                  </div>
                  <textarea className="textarea textarea-bordered m-2" name="userNote" defaultValue={u.userNote} placeholder="Any Others Info"></textarea>
                  <div className="form-control mt-6"></div>
                </div>

              )
              )
            }


            <div className="form-control mt-6">
              <button className="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
