import React, { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../hooks/hook.axios";
const LoggedInUsers = () => {
  const { user } = useContext(AuthContext);
  const searchRef = useRef();
  const [search, setSearch] = useState(null);

  let url = `/loggedInUsers?`;
  search && (url = url.concat(`s=${search}`));

  const { data: loggedInUsers = [], refetch } = useQuery({
    queryKey: ["loggedInUsers", search],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data;
    },
  });
  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };

  const handleMakeAdmin = (id) => {
    axios
      .put(`/loggedInUsers/admin/${id}`, { role: "admin" })
      .then((res) => {
        toast.success("Successfully make Admin");
        refetch();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };
  const handleMakeManager = (id) => {
    axios
      .put(`/loggedInUsers/admin/${id}`, { role: "acounts" })
      .then((res) => {
        toast.success("Successfully make Accounts");
        refetch();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300 m-2" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>
      <div>
        <h3 className="text-3xl">Admins & Users</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {loggedInUsers?.data?.map((lData, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{lData.name}</td>
                  <td>{lData.email}</td>
                  <td>
                    {lData?.role === "acounts" ? (
                      <button className="btn btn-secondary" onClick={() => handleMakeAdmin(lData._id)}>
                        Make Admin
                      </button>
                    ) : lData?.role === "user" ? (
                      <button className="btn btn-secondary" onClick={() => handleMakeManager(lData._id)}>
                        Make Accounts
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoggedInUsers;
