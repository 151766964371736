import React, { useEffect, useState } from "react";
import Salary from "./Salary/Salary";
import WoodPowderForm from "./WoodPowderForm/WoodPowderForm";
import WireForm from "./Wire/WireForm";
import AcidForm from "./Acid/AcidForm";
import Expense from "./Expense/Expense";
import { format } from "date-fns";
import Assets from "./Assets/Assets";
import toast from "react-hot-toast";
import axios from "../../hooks/hook.axios";
import ClientsPayment from "../CashIn/ClientsPayment/ClientsPayment";
import './CashOutCss/CashOut.css'
import FireRepairs from "./FireRepairs/FireRepairs";

const CashOut = () => {
  const [selectedDate, setDate] = useState(new Date());
  const date = format(selectedDate, "PP");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get("/userDetailsData")
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  }, []);

  const employees = users.filter((emplyee) => emplyee.catagories == "Employee");
  const acidAgents = users.filter((agent) => agent.catagories == "Acid Agent");
  const wordAgents = users.filter((agent) => agent.catagories == "Wood Powder Agent");
  const wireAgents = users.filter((agent) => agent.catagories == "Wire Agent");
  const Investor = users.filter((agent) => agent.catagories == "Investor");

  return (
    <div className="cashOut  grid lg:grid-cols-3 md:grid-cols-2 gap-3 lg:p-12 justify-items-center p-64">
      <Salary employees={employees} date={date}></Salary>
      <ClientsPayment Investor={Investor} date={date}></ClientsPayment>
      <WoodPowderForm agents={wordAgents} date={date}></WoodPowderForm>
      <WireForm agents={wireAgents} date={date}></WireForm>
      <AcidForm agents={acidAgents} date={date}></AcidForm>
      <Assets date={date}></Assets>
      <Expense date={date}></Expense>
      <FireRepairs date={date}></FireRepairs>
    </div>
  );
};

export default CashOut;
