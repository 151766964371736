import React, { useState } from 'react';
import Daypicker from '../Daypicker/Daypicker';
import { format } from 'date-fns';
import Carousel from '../Carousel/Carousel';
import Info from '../Info/Info';
import Faq from '../Faq/Faq';
import MIssion from '../Mission/MIssion';


const Home = () => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    return (
        <div className='bg:w-full'>


            <Carousel></Carousel>
            <Info></Info>
            <MIssion></MIssion>
            <Faq></Faq>
            

            {/* <div className="card lg:card-side bg-base-100 shadow-xl lg:ps-64">
                <figure><img src="https://daisyui.com/images/stock/photo-1494232410401-ad00d5433cfa.jpg" alt="Album" /></figure>
                <div className="card-body lg:ps-10">
                    <Daypicker
                        mode='single'
                        selectedDate={selectedDate}
                    />
                </div>
            </div> */}
            <div className='text-center'>
                <p className='bg-red-100'>Date : {format(selectedDate, 'PP')}</p>
            </div>
        </div>
    );
};

export default Home;