import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import { useQuery } from "react-query";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { formatMongoDBDate } from "../../../Shared/paymentDetails/Details";
import { useReactToPrint } from 'react-to-print';


const AssetsData = () => {
  const searchRef = useRef();
  const { Dashboard } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [id, setID] = useState(null);
  const [toDate, setToDate] = useState("");
  const contentToPrint = useRef(null);


  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  let url = `assetsCost?`;
  search && (url = url.concat(`s=${search}`));
  let nextDay = new Date(fromDate);
  nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
  fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
  const { data: assetsData = [], refetch } = useQuery({
    queryKey: ["assetsData", search, fromDate, toDate],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });

  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };
  const handleFormDate = () => {
    setFromDate(fromDateRef.current.value);
  };
  const handleToDate = () => {
    setToDate(toDateRef.current.value);
  };
  const handleSold = (e) => {
    e.preventDefault();
    const form = e.target;
    const amount = parseFloat(form.amount.value);

    axios
      .put(`/assetsCost/${id}`, { sold_status: true, sold_amount: amount })
      .then((res) => {
        toast.success("item sold");
        refetch();
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>
      <div className="flex justify-between mt-2">
        <div>
          <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 " type="date" />{" "}
          <button className="bg-red-300 p-1 md:m-1 " onClick={handleFormDate}>
            Search
          </button>

          <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 " type="date" />{" "}
          <button className="bg-red-300 p-1" onClick={handleToDate}>
            Search
          </button>
        </div>

        <button className="btn" onClick={() => {
          handlePrint(null, () => contentToPrint.current);
        }}>PRINT
        </button>
      </div>
      <div ref={contentToPrint} className="m-6">
        <h3 className="text-3xl">Assets Data</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Asset</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Note</th>
                <th>issued By</th>
                <th>Sold Amount</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {assetsData?.map((data, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{data.name}</td>
                  <td>{data.paid_amount}</td>
                  <td>{formatMongoDBDate(data.issue_date)}</td>
                  <td>{data.note}</td>
                  <td>{data.issued_by}</td>
                  <td>
                    {!data.sold_status ? (
                      <label htmlFor="sell_from" className="btn bg-red-100 px-16" onClick={() => setID(data._id)}>
                        Sell
                      </label>
                    ) : (
                      <>{data.sold_amount} </>
                    )}
                  </td>
                  <td>
                    {/* <Link to={`/dashboard/details?url=assetsCost?name=${data.name}`} className="btn btn-accent">
                      details
                    </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <input type="checkbox" id="sell_from" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="sell_from" className="btn my-2">
              X
            </label>
          </div>
          <form onSubmit={handleSold} className="grid grid-col-1 gap-3" method="dialog">
            <input
              name="amount"
              type="text"
              step="any"
              required
              placeholder=" Amount"
              className="input input-bordered w-full  "
            />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssetsData;
