import React, { useContext } from "react";
import { AuthContext } from "../../Context/AuthProvider";

function Privet({ children }) {
  const { user, loading } = useContext(AuthContext);
  if (loading) {
    return <div>loading </div>;
  }
  if (user?.role === "admin") {
    return children;
  }
  return <div>you are not admin</div>;
}

export default Privet;
