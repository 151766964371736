import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import { useQuery } from "react-query";
import axios from "../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import { formatMongoDBDate } from "../../../Shared/paymentDetails/Details";
import toast from "react-hot-toast";
import { useReactToPrint } from 'react-to-print';


const FireData = () => {
  const searchRef = useRef();
  const [search, setSearch] = useState("");
  const { Dashboard } = useContext(AuthContext);
  const [id, setID] = useState(null);
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const contentToPrint = useRef(null);


  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  let url = `fire?`;
  search && (url = url.concat(`s=${search}`));
  let nextDay = new Date(fromDate);
  nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
  fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
  const { data: fireData = [],refetch } = useQuery({
    queryKey: ["fireData", search, fromDate, toDate],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });

  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };
  const handleFormDate = () => {
    setFromDate(fromDateRef.current.value);
  };
  const handleToDate = () => {
    setToDate(toDateRef.current.value);
  };

  const handleEndTime = (e) => {
    e.preventDefault();
    const form = e.target;
    const note = form.endTime.value;
    console.log(note)

    axios
      .put(`/fire/${id}`, { fire_status: true, fire_time: note })
      .then((res) => {
        toast.success("End Fire");
        refetch();
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>

        <div className="flex justify-between mt-2">
       <div>
       <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 " type="date" />{" "}
        <button className="bg-red-300 p-1 md:m-1 " onClick={handleFormDate}>
          Search
        </button>
        
        <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 " type="date" />{" "}
        <button className="bg-red-300 p-1" onClick={handleToDate}>
          Search
        </button>
       </div>

        <button className="btn" onClick={() => {
          handlePrint(null, () => contentToPrint.current);
        }}>PRINT
        </button>
        </div>


      <div ref={contentToPrint}>
        <h3 className="text-3xl">Fire Data</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>Fire Number</th>
                <th>Roll</th>
                <th>Pot no.</th>
                <th>Weight</th>
                <th>Date</th>
                <th>Start Time/Note</th>
                <th>Fire Man</th>
                <th></th>
                <th>End Time</th>
              </tr>
            </thead>
            <tbody>
              {fireData?.map((data, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{data.roll}</td>
                  <td>{data.pot_number}</td>
                  <td>{data.weight}</td>
                  <td>{formatMongoDBDate(data.issue_date)}</td>
                  <td>{data.note}</td>
                  <td>{data.fire_man}</td>
                  <td>{data.issued_by}</td>
                  <td>
                    {!data.fire_status ? (
                      <label htmlFor="end_form" className="btn bg-red-100 px-16" onClick={() => setID(data._id)}>
                        End Time
                      </label>
                    ) : (
                      <>{data.fire_time} </>
                    )}
                  </td>
                  {/* <td>
                    {!data.note? (
                      <label htmlFor="end_form" className="btn bg-red-100 px-16" onClick={() => setID(data._id)}>
                        End Time
                      </label>
                    ) : (
                      <>{data.note} </>
                    )}
                  </td> */}


                  {/* <td>
                    <Link to={`/dashboard/details?url=assetsCost?name=${data.name}`} className="btn btn-accent">
                      details
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
      <input type="checkbox" id="end_form" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="end_form" className="btn my-2">
              X
            </label>
          </div>
          <form onSubmit={handleEndTime} className="grid grid-col-1 gap-3" method="dialog">
            <input
              name="endTime"
              type="text"
              step="any"
              required
              placeholder=" note"
              className="input input-bordered w-full  "
            />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>

    </div>
  );
};

export default FireData;
