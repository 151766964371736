import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";
const FireRepairs = ({date}) => {

    const { user, Dashboard } = useContext(AuthContext);

  const handleAssetsForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const date = form.date.value;
    const issuedBy = form.issuedBy.value;
    const potNumb = form.potNumb.value;
    const amount = parseFloat(form.amount.value);
    const note = form.note.value;

    const repairsDetails = {
      repairsAmount: amount,
      note: note,
      potNumb: potNumb,
      issue_date: date,
      issued_by: issuedBy,
    };

    console.log(repairsDetails)

    axios
      .post("repairsCost", repairsDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

    return (
        <div>
            {/* The button to open modal */}
            <label htmlFor="Fire_Repairs" className="btn bg-red-100 px-16">
                Pot Making & Repair
            </label>

            {/* Put this part before </body> tag */}
            <input type="checkbox" id="Fire_Repairs" className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box">
                    <div className="modal-action">
                        <label htmlFor="Fire_Repairs" className="btn my-2">
                            X
                        </label>
                    </div>

                    <form onSubmit={handleAssetsForm} className="grid grid-col-1 gap-3" method="dialog">
                        <h3 className="p-2">Pot Making & Repair</h3>
                        <input
                            name="issuedBy"
                            disabled
                            type="text"
                            value={`Issued By ${user?.displayName} `}
                            className="input input-bordered w-full  "
                        />

                        <input name="date" type="text" disabled value={date} className="input input-bordered w-full  " />
                        <input name="potNumb" type="text"required placeholder="Pot Number"  className="input input-bordered w-full  " />
                        <input name="amount" type="text" required placeholder=" Amount" className="input input-bordered w-full  " />
                        <input name="note" type="text" placeholder=" Note" className="input input-bordered w-full  " />

                        <input type="submit" value="Submit" className="input input-bordered w-full  " />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FireRepairs;