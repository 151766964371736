import React from 'react';

const DashboardHome = () => {
    return (
        <div>
            <h1>Under Development...! Coming soon......</h1>
        </div>
    );
};

export default DashboardHome;