import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const WoodPowderForm = ({ date, agents }) => {
  const { user, Dashboard } = useContext(AuthContext);

  const handleWoodForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const agents = form.agents.value;
    const date = form.date.value;
    const issuedBy = form.issuedBy.value;
    const note = form.note.value;

    const quantity = parseFloat(form.quantity.value);
    const price = parseFloat(form.rate.value);
    const paidAmount = parseFloat(form.paidAmount.value);
    const discountAmount = parseFloat(form.discountAmount.value);
    const totalBill = quantity * price;
    const due = totalBill - (paidAmount + discountAmount);

    const woodPowderDetails = {
      name: agents,
      qunatity: quantity,
      price: price.toFixed(2),
      total_price: totalBill.toFixed(2),
      paid_amount: paidAmount.toFixed(2),
      due_amount: due.toFixed(2),
      discount_amount: discountAmount.toFixed(2),
      issue_date: date,
      issued_by: issuedBy,
      note: note,
    };

    axios
      .post("powderCost", woodPowderDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      <div>
        {/* The button to open modal */}
        <label htmlFor="woodPowderForm" className="btn bg-red-100 px-16">
          Wood Powder Purchase
        </label>

        {/* Put this part before </body> tag */}
        <input type="checkbox" id="woodPowderForm" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            <div className="modal-action">
              <label htmlFor="woodPowderForm" className="btn my-2">
                X
              </label>
            </div>
            <form onSubmit={handleWoodForm} className="grid grid-col-1 gap-3" method="dialog">
            <h3 className="p-2">Wood Powder Form</h3>
              <input
                name="issuedBy"
                disabled
                type="text"
                value={`Issued By ${user?.displayName}`}
                className="input input-bordered w-full  "
              />

              <select name="agents" className="select select-bordered w-full">
                {agents.map((agent, i) => (
                  <option value={agent.name} key={i}>
                    {" "}
                    {agent.name}
                  </option>
                ))}
              </select>

              <input name="date" disabled type="text" value={date} className="input input-bordered w-full  " />

              <input
                name="quantity"
                type="text"
                placeholder="Quantity/(KG)"
                className="input input-bordered w-full"
                required
              />

              <input name="rate" type="text" placeholder="Rate per Unit" className="input input-bordered w-full  " />

              <input
                name="paidAmount"
                type="text"
                step='any'
                required
                placeholder="Paid Amount"
                className="input input-bordered w-full  "
              />
              <input
                name="discountAmount"
                type="text"
                placeholder="Discount Amount"
                step='any' required
                className="input input-bordered w-full  "
              />
              <input
                name="note"
                type="text"
                placeholder="note"
                step='any' required
                className="input input-bordered w-full  "
              />

              <input type="submit" value="Submit" className="input input-bordered w-full  " />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WoodPowderForm;
