import React from 'react';

const Faq = () => {
    return (
        <div>
            <section className="dark:bg-gray-100 dark:text-gray-800">
                <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
                    <p className="p-2 text-sm font-medium tracking-wider text-center uppercase">How it works</p>
                    <h2 className="mb-12 text-4xl font-bold leading-none text-center sm:text-5xl">Frequently Asked Questions</h2>
                    <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 dark:divide-gray-300">
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">What is furnace wire?
                            </summary>
                            <div className="px-4 pb-4">
                                <p>Furnace wire, often made from alloys like nichrome or Kanthal, is designed to withstand high temperatures, making it ideal for use in heating elements for furnaces, kilns, and other high-temperature industrial processes.</p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">What materials are commonly used in furnace wire?</summary>
                            <div className="px-4 pb-4">
                                <p>The most common materials for furnace wire are nichrome (a nickel-chromium alloy) and Kanthal (an iron-chromium-aluminum alloy). These materials are chosen for their excellent heat resistance and durability.</p>
                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">How does furnace wire work?</summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>When an electric current passes through the furnace wire, it heats up due to the electrical resistance of the wire. This heat is then used in applications like heating elements in industrial furnaces or other high-temperature devices.</p>

                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">What are the temperature limits for furnace wire?</summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>Nichrome wire can typically withstand temperatures up to around 1,200°C (2,192°F), while Kanthal wire can go up to 1,400°C (2,552°F) or higher, depending on the specific alloy.</p>

                            </div>
                        </details>
                        <details>
                            <summary className="py-2 outline-none cursor-pointer focus:underline">What factors should be considered when selecting furnace wire?
                            </summary>
                            <div className="px-4 pb-4 space-y-2">
                                <p>Key factors include the maximum operating temperature, the electrical resistance needed, the environment (e.g., presence of corrosive gases), and the wire’s mechanical strength at high temperatures.</p>

                            </div>
                        </details>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faq;