import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../../Context/AuthProvider";
import { useQuery } from "react-query";
import axios from "../../../../hooks/hook.axios";
import { Link } from "react-router-dom";
import { formatMongoDBDate } from "../../../../Shared/paymentDetails/Details";
const MwireData = () => {
  const searchRef = useRef();
  const [search, setSearch] = useState("");
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  let url = `mWireData?`;
  search && (url = url.concat(`s=${search}`));
  let nextDay = new Date(fromDate);
  nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
  fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
  const { data: mWireData = [] } = useQuery({
    queryKey: ["mWireData", search, fromDate, toDate],
    queryFn: async () => {
      const res = await axios.get(url);
      return res.data.data;
    },
  });
  console.log(mWireData);
  const handleSearch = () => {
    setSearch(searchRef.current.value);
  };
  const handleFormDate = () => {
    setFromDate(fromDateRef.current.value);
  };
  const handleToDate = () => {
    setToDate(toDateRef.current.value);
  };

  return (
    <div>
      <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
      <button className="bg-red-300 p-1" onClick={handleSearch}>
        Search
      </button>
      {/* <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 ml-20" type="date" />{" "}
      <button className="bg-red-300 p-1" onClick={handleFormDate}>
        Search
      </button>
      <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 ml-20" type="date" />{" "}
      <button className="bg-red-300 p-1" onClick={handleToDate}>
        Search
      </button> */}
      <div>
        <h3 className="text-3xl">MS Wire Data</h3>
        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Agent Name</th>
                {/* <th>Date</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>weight</th>
                <th>Price</th>
                <th>Paid Amount</th>
                <th>Due</th>
                <th>Discount Amount</th>
                <th>Action</th> */}

                <th></th>
              </tr>
            </thead>
            <tbody>
              {mWireData?.map((data, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{data.name}</td>
                  {/* <td>{formatMongoDBDate(data.issue_date)}</td>
                  <td>{data.size}</td>
                  <td>{data.qunatity}</td>
                  <td>{data.weight}</td>
                  <td>{data.paid_amount}</td>
                  <td>{data.total_price}</td>
                  <td>{data.due_amount}</td>
                  <td>{data.discount_amount}</td>
                  <td>{data.issued_by}</td> */}
                  <td>
                    <Link to={`/dashboard/details?url=mWireData?name=${data.name}`} className="btn btn-accent">
                      details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MwireData;
