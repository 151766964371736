import React from "react";
import Navbar from "../Shared/Navbar/Navbar";
import { Link, Outlet } from "react-router-dom";
import Privet from "../Routes/privet/acount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const DashboardLayout = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Privet>
        <div className="drawer lg:drawer-open">
          <input id="dashboard-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col p-2">
            <Outlet></Outlet>
          </div>
          <div className="drawer-side ">
            <label htmlFor="dashboard-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
            <ul className="menu p-4 w-80 min-h-full bg-black text-white">




              {/* Sidebar content here */}
              <h5>Cash Out Data</h5>
              <div className="divider divider-error"></div>
              <li>
                <Link to="/dashboard/fireData"><FontAwesomeIcon icon="fa-solid fa-fire" />Fire Data </Link>
              </li>
              <li>
                <Link to="/dashboard/fireRepairs"><FontAwesomeIcon icon="fa-solid fa-fire" />Pot Making & Repair</Link>
              </li>
              <li>
                <Link to="/dashboard/salaryData">Salary Data</Link>
              </li>
              <li>
                <Link to="/dashboard/othersData">Partner Payment </Link>
              </li>
              <li>
                <Link to="/dashboard/woodPowederData">Wood Powder Data</Link>
              </li>
              <li>
                <Link to="/dashboard/wireData">Wire Data</Link>
              </li>
              <li>
                <Link to="/dashboard/AcidData">Acid Data</Link>
              </li>
              <li>
                <Link to="/dashboard/assetsData">Assests Data</Link>
              </li>
              <li>
                <Link to="/dashboard/otherExpanses">Other Expanses</Link>
              </li>
              <br></br>
              <div className="divider"></div>

              <h5>Cash In Data</h5>
              <div className="divider divider-error"></div>
              <li>
                <Link to="/dashboard/furnace">Furnace Expanses</Link>
              </li>
              <li>
                <Link to="/dashboard/mwiresData">MS Wire Data</Link>
              </li>
              <li>
                <Link to="/dashboard/gIWires">Gi Wire</Link>
              </li>
              <li>
                <Link to="/dashboard/userDetailsData">Partners Info</Link>
              </li>
              <li>
                <Link to="/dashboard/investData">Invest Data</Link>
              </li>
              <li>
                <Link to="/dashboard/stock">Stock </Link>
              </li>
             



            </ul>
          </div>
        </div>
      </Privet>
    </div>
  );
};

export default DashboardLayout;
