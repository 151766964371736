import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const WireForm = ({ date, agents }) => {
  const { user, Dashboard } = useContext(AuthContext);

  const handleWireForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const agents = form.agents.value;
    const date = form.date.value;
    const issuedBy = form.issuedBy.value;
    const size = form.size.value;

    const quantity = parseFloat(form.quantity.value);
    const price = parseFloat(form.rate.value);
    const paidAmount = parseFloat(form.paidAmount.value);
    const discountAmount = parseFloat(form.discountAmount.value);
    const totalBill = quantity * price;
    const due = totalBill - (paidAmount + discountAmount);

    const wireDetails = {
      name: agents,
      size: size,
      price: price.toFixed(2),
      qunatity: quantity,
      paid_amount: paidAmount.toFixed(2),
      total_price: totalBill.toFixed(2),
      due_amount: due.toFixed(2),
      discount_amount: discountAmount.toFixed(2),
      issue_date: date,
      issued_by: issuedBy,
    };

    axios
      .post("wireCost", wireDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="wire_form" className="btn bg-blue-100 px-16">
        Wire Cost
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="wire_form" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="wire_form" className="btn my-2">
              X
            </label>
          </div>
          <form onSubmit={handleWireForm} className="grid grid-col-1 gap-3" method="dialog">
          <h3 className="p-2">Wire Purchase</h3>
            {/* if there is a button in form, it will close the modal */}
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName}`}
              className="input input-bordered w-full  "
            />
            <select name="agents" className="select select-bordered w-full ">
              {agents?.map((agent, i) => (
                <option value={agent.name} key={i}>
                  {" "}
                  {agent.name}
                </option>
              ))}
            </select>

            <select name="size" className="select select-bordered w-full ">
              <option disabled selected>
                Select Wire Size
              </option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>28</option>
              <option>30</option>
              <option>32</option>
            </select>
            <input name="date" type="text" value={date} disabled className="input input-bordered w-full  " />
            <input name="quantity" type="text" placeholder="Quantity" className="input input-bordered w-full  " />
            <input name="rate" type="text" placeholder="Rate per Unit" className="input input-bordered w-full  " />
            <input
              name="paidAmount"
              type="text"
              placeholder="Paid Amount"
              step='any'
              required
              className="input input-bordered w-full  "
            />
            <input
              name="discountAmount"
              type="text"
              placeholder="Discount Amount"
              step='any' required
              className="input input-bordered w-full  "
            />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>

    // <div>
    //     {/* You can open the modal using document.getElementById('ID').showModal() method */}
    //     <button className="btn bg-blue-100 p-16" onClick={() => document.getElementById('wire_form').showModal()}>Wire Cost</button>
    //     <dialog id="wire_form" className="modal">
    //         <div className="modal-box">
    //             <form onSubmit={handleWireForm} className='grid grid-col-1 gap-3' method="dialog">
    //                 {/* if there is a button in form, it will close the modal */}
    //                 <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button><br />

    //                 <select name='agents' className="select select-bordered w-full ">
    //                     <option disabled selected>Select Agent</option>
    //                     <option>Han Solo</option>
    //                     <option>Greedo</option>
    //                 </select>

    //                 <select name='size' className="select select-bordered w-full ">
    //                     <option disabled selected>Select Wire Size</option>
    //                     <option>8</option>
    //                     <option>10</option>
    //                     <option>12</option>
    //                     <option>14</option>
    //                     <option>16</option>
    //                     <option>18</option>
    //                     <option>20</option>
    //                     <option>22</option>
    //                     <option>24</option>
    //                     <option>26</option>
    //                     <option>28</option>
    //                     <option>30</option>
    //                     <option>32</option>
    //                 </select>
    //                 <input name='date' type="text" value={date} className="input input-bordered w-full  " />
    //                 <input name='quantity' type="number" placeholder="Quantity" className="input input-bordered w-full  " />
    //                 <input name='rate' type="number" placeholder="Rate per Unit" className="input input-bordered w-full  " />
    //                 <input name='paidAmount' type="number" placeholder="Paid Amount" className="input input-bordered w-full  " />
    //                 <input type="submit" value="Submit" className="input input-bordered w-full  " />

    //             </form>

    //         </div>
    //     </dialog>
    // </div>
  );
};

export default WireForm;
