import React from 'react';
import img1 from '../../assets/industry.webp'
import m1 from '../../assets/machine.webp'
import m2 from '../../assets/machine1.jpg'
import m3 from '../../assets/machine2.png'
import m4 from '../../assets/machine3.webp'
import m5 from '../../assets/machine4.jpg'

const Info = () => {
    return (
        <div>
            <div className="dark:bg-violet-600">
                <div className="container flex flex-col items-center px-4  mx-auto text-center lg:pb-56 md:py-6 md:px-10 lg:px-32 dark:text-gray-50">
                    <h1 className="text-5xl font-bold leading-none sm:text-6xl xl:max-w-3xl dark:text-gray-50">AMA Metal & Wire Industries</h1>
                    <p className="mt-6 mb-8 text-lg sm:mb-12 xl:max-w-3xl dark:text-gray-50">A Furnace Industry of BD</p>
                    <section className="dark:bg-gray-100 dark:text-gray-900">
                        <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">
                            <img src={m1} alt="" className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-3 md:row-start-1 dark:bg-gray-500 aspect-square" />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 dark:bg-gray-500 aspect-square" src={m2} />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 dark:bg-gray-500 aspect-square" src={m3} />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 dark:bg-gray-500 aspect-square" src={m4} />
                            <img alt="" className="w-full h-full rounded shadow-sm min-h-48 dark:bg-gray-500 aspect-square" src={m5} />

                        </div>
                    </section>

                </div>

            </div>


        </div>
    );
};

export default Info;