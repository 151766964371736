import React, { useContext, useState } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Salary = ({ date, employees }) => {
  const { user, Dashboard } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(null)

  const handleSalaryForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const employeeName = form.employee_name.value;
    const date = form.date.value;
    const salaryAmount = form.salaryAmount.value;
    const salaryConvToFloat = parseFloat(salaryAmount)
    const issuedBy = form.issuedBy.value;
    const note = form.note.value;
    const month = form.month.value;
    const assignedSalary = parseFloat(form.assignedSalary.value);
    const absent = parseFloat(form.absent.value);


    const salaryDetails = {
      name: employeeName,
      month:month,
      assignedSalary:assignedSalary,
      paid_amount: salaryConvToFloat.toFixed(2),
      issue_date: date,
      issued_by: issuedBy,
      absent: absent,
      note: note
    };
    console.log(salaryDetails)

    axios
      .post("salaryCollections", salaryDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };


  const handleSelectedUser = (e) => {
    const value = e.target.value;
    const filteredUsers = employees.filter(emp => emp.name === value);
    filteredUsers.map(users => (setSelectedUser(users.assignedSalary)))
  };

  console.log(selectedUser)

  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="salary_form" className="btn bg-red-100 px-16">
        <FontAwesomeIcon icon="fa-regular fa-money-bill" /> Salary
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="salary_form" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="salary_form" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleSalaryForm} className="grid grid-col-1 gap-3" method="dialog">
            <h3 className="p-2">Salary Form</h3>
            {/* if there is a button in form, it will close the modal */}
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName}`}
              className="input input-bordered w-full  "
            />

            <select onBlur={handleSelectedUser} name="employee_name" className="select select-bordered w-full ">
              <option >Select</option>
              {employees.map((employee, i) => (
                <option value={employee.name} key={i}>
                  {" "}
                  {employee.name}
                </option>
              ))}
            </select>

            <select name="month" className="select select-bordered w-full ">
              <option >Select Month</option>
              <option >January</option>
              <option >February</option>
              <option >March</option>
              <option >April</option>
              <option >May</option>
              <option >June</option>
              <option >July</option>
              <option >August</option>
              <option >September</option>
              <option >October</option>
              <option >November</option>
              <option >December</option>

            </select>
            <input name="date" disabled type="text" value={date} className="input input-bordered w-full  " />
            <input
              name="salaryAmount"
              type="text"
              step='any'
              required
              placeholder="Salary Amount"
              className="input input-bordered w-full"
            />

              <small>Assigned Salary :</small>
            <input
              name="assignedSalary"
              type="text"
              step='any'
              required
              placeholder="Assigned Salary"
              value={selectedUser}
              className="input input-bordered w-full  "
            />
            <input
              name="absent"
              type="text"
              step='any'
              required
              placeholder="Absent Count"
              className="input input-bordered w-full  "
            />

            <input
              name="note"
              type="text"
              step='any'
              required
              placeholder="Note"
              className="input input-bordered w-full  "
            />

            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Salary;
