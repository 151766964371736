import axios from "axios";

const errorArry = [401, 403, 406, 500, 400];

const instance = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTION === "true" ? process.env.REACT_APP_SERVER_URL : "http://localhost:5000",
  timeout: 120 * 1000,
  headers: { Accept: "application/json", authorization: `bearer ${localStorage.getItem("accessToken")}` },
  withCredentials: true,
});
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (errorArry.includes(error.response?.status)) {
      console.log(error);
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
