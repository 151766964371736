import React, { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "../../../hooks/hook.axios";
import { useReactToPrint } from 'react-to-print';

import { Link } from "react-router-dom";
// import { formatMongoDBDate } from "../../../../Shared/paymentDetails/Details";
const RepairsCost = () => {

    const searchRef = useRef();
    const [search, setSearch] = useState("");
    const fromDateRef = useRef();
    const toDateRef = useRef();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const contentToPrint = useRef(null);


    const handlePrint = useReactToPrint({
      documentTitle: "Print This Document",
      onBeforePrint: () => console.log("before printing..."),
      onAfterPrint: () => console.log("after printing..."),
      removeAfterPrint: true,
    });

    let url = `repairsCost?`;
    search && (url = url.concat(`s=${search}`));
    let nextDay = new Date(fromDate);
    nextDay = toDate ? toDate : nextDay.setDate(nextDay.getDate() + 1);
    fromDate && (url = url.concat(`&issue_date>=${fromDate}&issue_date<=${nextDay} `));
    const { data: repairsCost = [] } = useQuery({
        queryKey: ["repairsCost", search, fromDate, toDate],
        queryFn: async () => {
            const res = await axios.get(url);
            return res.data.data;
        },
    });
    const handleSearch = () => {
        setSearch(searchRef.current.value);
    };
    const handleFormDate = () => {
        setFromDate(fromDateRef.current.value);
    };
    const handleToDate = () => {
        setToDate(toDateRef.current.value);
    };


    return (
        <div>
            <input ref={searchRef} className="input-sm bg-gray-300" type="text" />{" "}
            <button className="bg-red-300 p-1" onClick={handleSearch}>
                Search
            </button>
            <div className="flex justify-between mt-2">
                <div>
                    <input ref={fromDateRef} onChange={handleFormDate} className="input-sm bg-gray-300 " type="date" />{" "}
                    <button className="bg-red-300 p-1 md:m-1 " onClick={handleFormDate}>
                        Search
                    </button>

                    <input ref={toDateRef} onChange={handleToDate} className="input-sm bg-gray-300 " type="date" />{" "}
                    <button className="bg-red-300 p-1" onClick={handleToDate}>
                        Search
                    </button>
                </div>

                <button className="btn" onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                }}>PRINT
                </button>
            </div>

            <div ref={contentToPrint} className="m-6">
                <h3 className="text-3xl">Pot Making & Repair</h3>
                <div className="overflow-x-auto">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                <th>Pot no.</th>
                                <th>Amount</th>
                                <th>Note</th>
                                <th>date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {repairsCost?.map((data, i) => (
                                <tr key={i}>
                                    <td>{data.potNumb}</td>
                                    <td>{data.repairsAmount}</td>
                                    <td>{data.note}</td>
                                    <td>{data.issue_date}</td>
                                    <td>{data.issued_by}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RepairsCost;