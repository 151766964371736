import axios from "../../hooks/hook.axios";
import React, { useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../Context/AuthProvider";
import { format } from "date-fns";
const FireCount = () => {
  const { user, Dashboard } = useContext(AuthContext);
  const date = new Date();
  const formate = format(date, "PP");

  const handleFireCount = (event) => {
    event.preventDefault();
    const form = event.target;
    const issue_date = new Date();

    const issuedBy = form.issuedBy.value;
    const roll = form.Roll.value;
    const potCount = form.potCount.value;
    const weight = form.weight.value;
    const note = form.note.value;
    const fireMan = form.fireMan.value;

    const FireCountDetails = {
      issue_date: issue_date,
      issued_by: issuedBy,
      roll: roll,
      pot_number: potCount,
      weight: weight,
      note: note,
      fire_man: fireMan,
    };

    console.log(FireCountDetails)

    axios
      .post("fire", FireCountDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="FireCount" className="btn bg-red-100 px-16">
        Fire Count
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="FireCount" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="FireCount" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleFireCount} className="grid grid-col-1 gap-3" method="dialog">
          <h3 className="p-2">Fire Count</h3>
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName} `}
              className="input input-bordered w-full  "
            />

            <input name="date" type="text" disabled value={date} className="input input-bordered w-full  " />

            <input name="Roll" type="text" placeholder="Roll" className="input input-bordered w-full  " />
            <input name="potCount" type="text" placeholder="Pot Number" className="input input-bordered w-full  " />

            <input name="weight" type="text" placeholder="Weight" className="input input-bordered w-full  " />
            <input name="fireMan" type="text" placeholder="Fire Man" className="input input-bordered w-full  " />
            <input name="note" type="text" placeholder="note" className="input input-bordered w-full  " />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FireCount;
