import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const Stock = ({ date, clients }) => {
  const { user, Dashboard } = useContext(AuthContext);

  const handleMsWire = (event) => {
    event.preventDefault();
    const form = event.target;
    const stock = form.stock.value;

    const stockDetails = {
      wire_stock: stock,
    };

    axios
      .put("dashboard", stockDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };
  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="stock_from" className="btn bg-red-100 px-16">
        Stock
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="stock_from" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="stock_from" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleMsWire} className="grid grid-col-1 gap-3" method="dialog">
          <h3 className="p-2">Stock</h3>
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName} `}
              className="input input-bordered w-full  "
            />

            <input name="stock" type="text" step='any'
              required placeholder="Add KG" className="input input-bordered w-full  " />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Stock;
