import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const InvestorForm = ({ date, investors }) => {
  const { user, Dashboard } = useContext(AuthContext);
  console.log(investors);
  const handleInvestorForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const investorName = form.name.value;
    const date = form.date.value;
    const issuedBy = form.issuedBy.value;
    const amount = parseFloat(form.amount.value);
    const note = form.note.value;
    const investorDetails = {
      name: investorName,
      issued_by: issuedBy,
      issue_date: date,
      paid_amount: amount,
      note: note,
    };
    axios
      .post("/investorData", investorDetails)
      .then((res) => {
        console.log(res.data);
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="investor_from" className="btn bg-red-100 px-16">
        Invest Form
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="investor_from" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="investor_from" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleInvestorForm} className="grid grid-col-1 gap-3" method="dialog">
          <h3 className="p-2">Investor</h3>
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName} `}
              className="input input-bordered w-full  "
            />
            <select name="name" className="select select-bordered w-full ">
              {investors.map((investor, i) => (
                <option value={investor.name} key={i}>
                  {" "}
                  {investor.name}
                </option>
              ))}
            </select>
            <input name="date" type="text" disabled value={date} className="input input-bordered w-full  " />
            <input name="amount" type="text" step='any'
              required
              placeholder=" Amount" className="input input-bordered w-full  " />
            <input name="note" type="text" placeholder=" Note" className="input input-bordered w-full  " />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvestorForm;
