import React, { createContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import app from "../Firebase/firebase.config";
import axios from "../hooks/hook.axios";
import { useQuery } from "react-query";
export const AuthContext = createContext();
const auth = getAuth(app);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const Dashboard = useQuery({
    queryKey: ["dashboard"],
    queryFn: async () => {
      const res = await axios.get("/dashboard");
      return res.data.data;
    },
  });

  const createUser = (email, password) => {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const GoogleSignIn = (provider) => {
    setLoading(true);
    return signInWithPopup(auth, provider);
  };
  const signIn = (email, password) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const updateUser = (userInfo) => {
    // setLoading(true)
    return updateProfile(auth.currentUser, userInfo);
  };

  const logOut = () => {
    localStorage.removeItem("accessToken");
    setLoading(true);
    return signOut(auth);
  };
 
  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, async (CurrentUser) => {
      try {
        const Token = await axios.post("/jwt", CurrentUser);
        localStorage.setItem("accessToken", Token.data.data);
        const User = await axios.get(`/loggedInUsers?email=${CurrentUser.email}`);
        setUser({ ...CurrentUser, ...User.data.data[0] });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    });
    return () => unSubscribe();
  }, []);

  const authInfo = {
    createUser,
    signIn,
    logOut,
    loading,
    setUser,
    user,
    updateUser,
    GoogleSignIn,
    Dashboard,
  };
  return <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
