import React from 'react';
import furnaceIMG from "../../assets/furnaceWire.webp"
import roleIMG from "../../assets/role-of-furnace-cable.png"
import evoIMG from "../../assets/evolution.webp"

const MIssion = () => {
    return (
        <div>
            <section className="p-4 lg:p-8 dark:bg-gray-100 dark:text-gray-800">
                <div className="container mx-auto space-y-12">
                    <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
                        <img src={furnaceIMG} alt="" className="h-80 dark:bg-gray-500 aspect-video" />
                        <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
                            {/* <span className="text-xs uppercase dark:text-gray-600">Join, it's free</span> */}
                            <h3 className="text-3xl font-bold">Furnace Wire</h3>
                            <p className="my-6 dark:text-gray-600">Furnace wire is designed to handle high temperatures, often used in environments where extreme heat is present, such as in electric furnaces and other high-heat equipment. It’s built to withstand intense conditions without degrading, ensuring reliable performance and safety. If you’re into the technical side, furnace wire typically has a special insulation that resists heat, ensuring longevity and efficiency in demanding situations.</p>
                        </div>
                    </div>
                    <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
                        <img src={roleIMG} alt="" className="h-80 dark:bg-gray-500 aspect-video" />
                        <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
                            {/* <span className="text-xs uppercase dark:text-gray-600">Join, it's free</span> */}
                            <h3 className="text-3xl font-bold">The Crucial Role of Furnaces in Cable</h3>
                            <p className="my-6 dark:text-gray-600">Furnaces play a vital role in cable and conductor manufacturing, particularly in the processes of melting, annealing, and heat treatment. They are essential for melting raw materials like copper and aluminum, ensuring these metals reach the precise temperatures needed for drawing into fine wires. In the annealing process, furnaces heat the wires to relieve internal stresses, enhancing their flexibility and electrical conductivity. Heat treatment through furnaces also refines the microstructure of the metal, improving its mechanical properties and durability. Moreover, controlled heating ensures uniformity in the material, which is crucial for the consistent quality of cables and conductors. This precision in temperature control and heat application provided by furnaces is indispensable, as it directly impacts the efficiency, performance, and longevity of the final electrical products.</p>
                        </div>
                    </div>
                    <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
                        <img src={evoIMG} alt="" className="h-80 mt-9 dark:bg-gray-500 aspect-video" />
                        <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-50">
                            {/* <span className="text-xs uppercase dark:text-gray-600">Join, it's free</span> */}
                            <h3 className="text-3xl font-bold">Evolution of Furnace Industries</h3>
                            <p className="my-6 dark:text-gray-600">The furnace industry has evolved significantly from its early days of simple, manually operated systems to today’s highly sophisticated, automated technologies. Initially, furnaces were basic and relied on coal or wood as fuel, with limited temperature control and efficiency. The Industrial Revolution marked a pivotal shift with the advent of steam-powered and later gas-fired furnaces, enabling higher temperatures and greater production capacity.

                                In the 20th century, advancements in metallurgy and material sciences led to the development of electric and induction furnaces, offering precise control over temperature and energy use, which was critical for industries like steelmaking and glass production. The integration of computer systems and automation in the late 20th and early 21st centuries further enhanced furnace operations, enabling real-time monitoring and optimization of processes. Today, the focus is on energy efficiency, environmental impact, and the use of renewable energy sources, reflecting a broader trend towards sustainability in the furnace industry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MIssion;