import axios from "../../hooks/hook.axios";
import React from "react";
import toast from "react-hot-toast";

const AddUsers = () => {
  const handleUserForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const catagories = form.catagories.value;
    const name = form.name.value;
    const address = form.address.value;
    const contact = form.contact.value;
    const nid = form.nid.value;
    const userNote = form.userNote.value;
    const fname = form.fname.value;
    const mname = form.mname.value;
    const assignedSalary = form.assignedSalary.value;

    const userDetails = {
      catagories,
      name,
      address,
      contact,
      nid,
      userNote,
      fname,
      mname,
      assignedSalary
    };
    console.log(userDetails);
    axios
      .post("userDetailsData", userDetails)
      .then((res) => {
        toast.success("Confirmed");
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div className=" flex md:justify-center ">
      <div className="w-1/2 text-center lg:p-2">
        <div className="card  shadow-xl lg:p-2">
          <form onSubmit={handleUserForm} className="">
            <div className="form-control">
              <select name="catagories" className="select select-bordered w-full ">
                <option>Client</option>
                <option> Wood Powder Agent</option>
                <option> Wire Agent</option>
                <option> Acid Agent</option>
                <option>Employee</option>
                <option>Investor</option>
              </select>
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input type="text" name="name" placeholder="Name" className="input input-bordered" required />
              <label className="label">
                <span className="label-text">Father's Name</span>
              </label>
              <input type="text" name="fname" placeholder="Father's Name" className="input input-bordered" required />
              <label className="label">
                <span className="label-text">Mother's Name</span>
              </label>
              <input type="text" name="mname" placeholder="Mother's Name" className="input input-bordered" required />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Address</span>
              </label>
              <input type="text" name="address" placeholder="Address" className="input input-bordered" required />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Contact</span>
              </label>
              <input type="number" name="contact" placeholder="Contact" className="input input-bordered" required />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">NID/Trade License</span>
              </label>
              <input type="text" name="nid" placeholder="NID/Trade License" className="input input-bordered" required />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Assigned Salary</span>
              </label>
              <input type="text" name="assignedSalary" placeholder="Amount" defaultValue={`N/A`} className="input input-bordered" required />
            </div>
            <textarea className="textarea textarea-bordered" name="userNote" placeholder="Any Others Info"></textarea>
            <div className="form-control mt-6">
              <button className="btn btn-primary">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
