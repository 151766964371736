import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthProvider";
import toast from "react-hot-toast";
import axios from "../../../hooks/hook.axios";

const Furnace = ({ date, clients }) => {
  const { user, Dashboard } = useContext(AuthContext);

  const handleFurnaceForm = (event) => {
    event.preventDefault();
    const form = event.target;

    const clients = form.clients.value;
    const size = form.size.value;
    const date = form.date.value;
    const issuedBy = form.issuedBy.value;
    const note = form.note.value;

    const quantity = parseFloat(form.quantity.value);
    const price = parseFloat(form.rate.value);
    const paidAmount = parseFloat(form.paidAmount.value);
    const discountAmount = parseFloat(form.discount.value);
    const weight = parseFloat(form.weight.value);
    const totalBill = weight * price;
    const due = totalBill - (paidAmount + discountAmount);

    const furnaceDetails = {
      name: clients,
      issue_date: date,
      size: size,
      qunatity: quantity,
      price: price.toFixed(2),
      paid_amount: paidAmount.toFixed(2),
      total_price: totalBill.toFixed(2),
      due_amount: due.toFixed(2),
      weight: weight,
      discount_amount: discountAmount.toFixed(2),
      issued_by: issuedBy,
      note: note,
    };

    console.log(furnaceDetails);
    axios
      .post("furnaceData", furnaceDetails)
      .then((res) => {
        toast.success("Confirmed");
        Dashboard.refetch();
        form.reset();
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  };

  return (
    <div>
      {/* The button to open modal */}
      <label htmlFor="furnace_form" className="btn bg-red-100 px-16">
        Furnace Form
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="furnace_form" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box">
          <div className="modal-action">
            <label htmlFor="furnace_form" className="btn my-2">
              X
            </label>
          </div>

          <form onSubmit={handleFurnaceForm} className="grid grid-col-1 gap-3" method="dialog">
            <h3 className="p-2">Furnace</h3>
            <input
              name="issuedBy"
              disabled
              type="text"
              value={`Issued By ${user?.displayName} `}
              className="input input-bordered w-full  "
            />
            <select name="clients" className="select select-bordered w-full ">
              {clients?.map((client, i) => (
                <option value={client.name} key={i}>
                  {" "}
                  {client.name}
                </option>
              ))}
            </select>

            <input name="date" type="text" disabled value={date} className="input input-bordered w-full  " />
            <select name="size" className="select select-bordered w-full ">
              <option disabled selected>
                Select Wire Size
              </option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
              <option>21</option>
              <option>22</option>
              <option>23</option>
              <option>24</option>
              <option>25</option>
              <option>26</option>
              <option>28</option>
              <option>30</option>
              <option>32</option>
            </select>
            <input name="quantity" type="text" placeholder="Roll Quantity" required className="input input-bordered w-full  " />
            <input name="weight" type="text" placeholder="Weight" required className="input input-bordered w-full  " />
            <input name="rate" type="text" placeholder="Rate per Unit" required className="input input-bordered w-full  " />
            <input
              name="paidAmount"
              type="text"
              placeholder="Paid Amount"
              step="any"
              required
              className="input input-bordered w-full  "
            />
            <input
              name="discount"
              type="text"
              placeholder="Discount Amount"
              step="any"
              required
              className="input input-bordered w-full  "
            />
            <input
              name="note"
              type="text"
              placeholder="Note"
              step="any"
              className="input input-bordered w-full  "
            />
            <input type="submit" value="Submit" className="input input-bordered w-full  " />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Furnace;
