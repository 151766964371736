import React from 'react';
import { DayPicker } from 'react-day-picker';

const Daypicker = () => {
    return (
        <div>
            <DayPicker></DayPicker>
        </div>
    );
};

export default Daypicker;