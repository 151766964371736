import React, { useEffect, useState } from "react";
import Furnace from "./Furnace/Furnace";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import MsWire from "./MSwire/MsWire";
import GiWire from "../CashOut/GiWires/GiWire";
import InvestorForm from "./Investor/InvestorForm";
import ClientsPayment from "./ClientsPayment/ClientsPayment";
import toast from "react-hot-toast";
import axios from "../../hooks/hook.axios";
import Stock from "./Stock/Stock";
import FireCount from "../FireCount/FireCount";
import './CashInCss/CashIn.css'


const CashIn = () => {
  const [selectedDate, setDate] = useState(new Date());
  const date = format(selectedDate, "PP");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get("/userDetailsData")
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => toast.error(err.message ? err.message : err?.statusText));
  }, []);
  const clients = users.filter((client) => client.catagories == "Client");
  const investors = users.filter((investor) => investor.catagories == "Investor");

  return (
    <div className="">
      <div className="cashIn grid bg-red-500 lg:grid-cols-3 md:grid-cols-2 gap-3 lg:p-12 justify-items-center p-2">
        <Furnace clients={clients} date={date}></Furnace>
        <MsWire clients={clients} date={date}></MsWire>
        <GiWire clients={clients} date={date}></GiWire>
        {/* <ClientsPayment clients={clients} date={date}></ClientsPayment> */}
        <InvestorForm investors={investors} date={date}></InvestorForm>
        <FireCount date={date}></FireCount>
        <Stock> </Stock>
      </div>
    </div>
  );
};

export default CashIn;
